.footer{
    background-color: #231C1E;
    color: #fff;
    padding: 0 40px;

    @include responsive(md){
        padding: 0 20px;
    }

    .inner{
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        
        .footer-content{
            display: flex;
            justify-content: space-between;
            padding: 60px 0;

            @include responsive(sm){
                flex-wrap: wrap;
            }
            @include responsive(xs){
                justify-content: center;
            }

            .logo-wrap{

                @include responsive(sm){
                    order: 1;
                }

                .logo{
                    width: 150px;

                    @include responsive(md){
                        width: 100px;
                    }
                }
            }
            .content{
                width: 60%;
                display: flex;
                justify-content: space-around;
                align-items: center;

                @include responsive(sm){
                    gap: 50px;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    margin-top: 50px;
                    order: 3;
                }
                @include responsive(xs){
                    gap: 40px;
                    flex-direction: column;
                    align-items: center;
                }

                .location{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    margin-bottom: auto;

                    @include responsive(sm){
                        margin: 0;    
                        gap: 20px;
                    }
                    @include responsive(xs){
                        align-items: center;
                        gap: 10px;
                    }
                    
                    .heading{
                        font-size: 39px;
                        text-transform: capitalize;
                        font-family: 'Bentago';
                        font-weight: 500;

                        @include responsive(xs){
                            font-size: 32px;
                        }
                    }
                    a{
                        font-size: 16px;
                        font-family: 'Charlevoix Pro';
                        max-width: 130px;
                        color: #fff;
                        text-decoration: none;

                        @include responsive(xs){
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
                .menu{
                    // margin-bottom: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @include responsive(sm){
                        margin: 0;  
                        text-align: end;  
                    }
                    @include responsive(xs){
                        text-align: center;
                        gap: 10px;
                    }

                    .heading{
                        font-size: 39px;
                        font-family: 'Bentago';
                        font-weight: 500;

                        @include responsive(xs){
                            font-size: 32px;
                        }
                    }
                    .links{
                        list-style-type: none;

                        .link{
                            margin-bottom: 8px;
                            font-size: 16px;

                            @include responsive(xs){
                                font-size: 14px;
                            }

                            a{
                                text-decoration: none;
                                color: #fff;
                                text-transform: capitalize;
                                font-family: 'Charlevoix Pro';
                                font-weight: 300;
                                &.active{
                                    text-decoration: underline;
                                    color: #5E7584;
                                }
                                transition: 300ms;
                                &:hover{
                                    text-decoration: underline;
                                    color: #5E7584;
                                }
                            }
                        }
                    }
                }
            }
            .logos{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;

                @include responsive(sm){
                    order: 2;    
                    flex-direction: row;
                }
                @include responsive(xs){
                    order: 3;  
                    margin-top: 50px;  
                }

                .logo-wrap{
                    width: 70px;

                    @include responsive(md){
                        width: 50px;
                    }

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .exr{
                    @include responsive(sm){
                       margin-top: 15px;
                    }
                }
            }  
        }
        .lower-wrap{
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            border-top: 2px solid #fff;

            @include responsive(sm){
                flex-direction: column;
                gap: 10px;
            }

            .copyright{

                @include responsive(sm){
                    order: 1;
                }
                p{
                    font-size: 16px;
                    font-family: 'Charlevoix Pro';

                    @include responsive(sm){
                        font-size: 14px;
                    }
                    @include responsive(xs){
                        text-align: center;
                    }
                }
            }
            .extra-links{

                display: flex;
                gap: 20px;
                list-style-type: none;

                @include responsive(sm){
                    order: 0;
                }
                @include responsive(xs){
                    justify-content: center;
                }

                .link{
                    font-size: 16px;

                    @include responsive(sm){
                        font-size: 14px;
                    }

                    p{
                        cursor: pointer;
                        text-decoration: none;  
                        color: #fff;
                        text-transform: capitalize;
                        font-family: 'Charlevoix Pro';
                        // font-weight: 300;
                        transition: 300ms;

                        &:hover{
                            text-decoration: underline;
                            color: #5E7584;
                            text-decoration-color: #8A7D73;
                        }
                    }
                }
                
            }
        }
    }
}