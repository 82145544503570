@font-face {
    font-family: 'Charlevoix Pro';
    src: url(../assets/fonts/CharlevoixPro-Regular.ttf);
}
@font-face {
    font-family: 'Charlevoix Light';
    src: url(../assets/fonts/CharlevoixPro-Light-1.ttf);
}
@font-face {
    font-family: 'Bentago';
    src: url(../assets/fonts/Black-Studio-Bentoga-Thin.ttf);
}