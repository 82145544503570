.form-image{
    background-color: #5e7584;
    padding: 55px 40px;

    @include responsive(xl){
        padding: 55px 30px;
    }
    @include responsive(sm){
        padding: 55px 10px;
    }

    .inner{
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        gap: 50px;

        @include responsive(xl){
            gap: 30px;
        }
        @include responsive(md){
            flex-direction: column;
        }

        .content{
            width: 50%;
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-end;

            @include responsive(md){
                width: 100%;
            }
            .form{
                input, select, textarea{
                    height: 66px;
                    width: 100%;
                    text-align: center;
                    border-radius: 3px;
                    background-color: #f4e9d7;
                    margin-bottom: 20px;
                    padding: 0 30px;
                    border: 0;
                    font-size: 18px;
                    font-family: 'Charlevoix Pro';
                    &:focus, &:focus-visible{
                        border: none;
                        outline: none;
                        outline-offset: 0;
                    }
                    &::placeholder{
                        color: rgba(0, 0, 0);
                    }
                }
                .date{
                    min-width: calc(100% - 18px);
                    color: rgba(0, 0, 0);
                    text-align: center;
                }
                textarea{
                    padding: 20px 30px;
                    height: 140px;
                    resize: none;
                }
                .select-wrap{
                    position: relative;
                    svg{
                        width: 20px;
                        right: 30px;
                        pointer-events: none;
                        top: calc(50% - 20px);
                        // transform: translateY(-50%);
                        position: absolute;
                    }
                    select { 
                        /* for Firefox */ 
                        -moz-appearance: none; 
                        /* for Safari, Chrome, Opera */ 
                        -webkit-appearance: none;
                        color: rgba(0, 0, 0);
                        &::-ms-expand{
                            opacity: 0;
                        } 
                    }
                    .layout{
                        text-align-last: center;
                        option {
                            text-align: center;
                            &:checked{
                                color: rgba(0, 0, 0);
                            }
                            &::placeholder{
                                color: rgba(0, 0, 0);
                            }
                            &::selection{
                                color: rgba(0, 0, 0);
                            }
                        }
                    }
                }
                input[type="date"]::-webkit-inner-spin-button,
                input[type="date"]::-webkit-calendar-picker-indicator {
                    cursor: pointer;
                    -webkit-appearance: none;
                    opacity: 0;
                }
                // input[type="date"]::-webkit-datetime-edit-year-field:not([aria-valuenow]),
                // input[type="date"]::-webkit-datetime-edit-month-field:not([aria-valuenow]),
                // input[type="date"]::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
                //     // color: transparent;
                // }

                //HIDE THE DEFAULT PLACEHOLDER
                input[type=date]:required:invalid::-webkit-datetime-edit {
                    color: transparent;
                    text-align: center;
                }
                input[type=date]:focus::-webkit-datetime-edit {
                    color: black !important;
                }
                // ::-webkit-datetime-edit { padding: 1em; }
                // ::-webkit-datetime-edit-fields-wrapper { display: none;}
                // ::-webkit-datetime-edit-text { display: none; }
                // ::-webkit-datetime-edit-month-field { display: none; }
                // ::-webkit-datetime-edit-day-field { display: none; }
                // ::-webkit-datetime-edit-year-field { display: none; }
                // ::-webkit-inner-spin-button { display: none; }
                // ::-webkit-calendar-picker-indicator { background: transparent; }

                // TO ADD THE PLACEHOLDER
                input[type="date"]::before{
                    color: rgb(0, 0, 0,);
                    content: attr(placeholder);
                    top: calc(50% - 10px);
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                input[type="date"]:focus::before{
                    content: "" !important;
                    display: none;
                }


                .date-wrap{
                    position: relative;
                    svg{
                        width: 20px;
                        right: 30px;
                        pointer-events: none;
                        top: calc(50% - 10px);
                        transform: translateY(-50%);
                        position: absolute;
                    }
                }
                .button{
                    background-color: #996936;
                    color: white;
                    font-family: "bentago";
                    font-size: 33px;
                    width: 100%;
                    border: 0;
                    padding: 25px;
                    cursor: pointer;
                    border-radius: 3px;
                    transition: 300ms;
                    &:hover{
                        background-color: #231c1e;
                    }
                }
                #main_form{

                    .thankyou{
                        text-align: center;
                        margin-bottom: 20px;
                        
                        h2{
                            font-family: 'Bentago';
                            font-size: 72px;
                            line-height: 1.3em;
                            margin-bottom: 20px;
                            font-weight: 500;
                            color: #fff;

                            @include responsive(md){
                                font-size: 65px;
                            }
                            @include responsive(sm){
                                font-size: 48px;
                            }
                        }
                        p{
                            color: #fff;
                            font-size: 18px;
                            font-family: 'Charlevoix Pro';
                            line-height: 1.8em;
                            font-weight: lighter;

                            @include responsive(md){
                                font-size: 16px;
                            }
                        }
                    }
                }
                
            }
            .secondary-option{
                width: 100%;
                text-align: center;
                p{
                    font-size: 24px;
                    color: white;
                    margin-bottom:30px;
                }
                a{
                    background-color: #996936;
                    color: white;
                    font-family: "bentago";
                    font-size: 33px;
                    width: 100%;
                    border: 0;
                    padding: 25px;
                    cursor: pointer;
                    border-radius: 3px;
                    transition: 300ms;
                    display: block;
                    text-decoration: none;
                    &:hover{
                        background-color: #231c1e;
                        text-decoration: underline;
                    }
                }

                
            }
            .text-button{
                width: 100%;
                padding: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #FFFFFF26;
                border-radius: 30px;
                margin-top: auto;

                .txt{
                    color: #fff;
                    font-size: 28px;
                    font-family: 'Bentago';
                    margin-bottom: 20px;
                }

                a{
                    font-size: 15px;
                    text-decoration: none;
                    font-family: 'Charlevoix Pro';
                    padding: 12px 24px;
                    background-color: #2d4241;
                    color: #fff;
                    border-radius: 3px;
                    transition: 300ms;

                    &:hover{
                        background-color: #000;
                        text-decoration: underline;
                    }
                }
            }
        }
        .image-wrap{
            width: 50%;

            @include responsive(md){
                display: none;
            }
            img{
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}