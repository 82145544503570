.banner-content{   
    background-color: #5e7584;
    padding: 0 40px;

    @include responsive(sm){
        padding: 0 20px;
    }

    .inner{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 0;
        

        .heading{
            color: #fff;
            font-size: 87px;
            text-align: center;
            font-family: 'Bentago';
            font-weight: 500;
            line-height: 100%;
            margin-bottom: 20px;
            max-width: 1550px;

            @include responsive(xl){
                font-size: 78px;
            }
            @include responsive(lg){
                font-size: 72px;
            }
            @include responsive(md){
                font-size: 65px;
            }
            @include responsive(sm){
                font-size: 55px;
            }
            @include responsive(xs){
                font-size: 48px;
            }
        }
        .description{
            color: #fff;
            font-size: 18px;
            font-family: 'Charlevoix Pro';
            text-align: center;
            margin-top: 20px;
            width: 60%;
            line-height: 2;
            @include responsive(md){
                width: 100%;
                font-size: 16px;
            }
        }  
    }
}