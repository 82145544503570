.table{
    background-color: #5e7584;
    padding: 40px;
    @include responsive(md){
        padding: 40px 10px;
    }
    .inner{
        max-width: 1440px;
        margin: 0 auto;
        .subtable{
            margin-bottom: 40px;
            &.index0{
                :first-child{
                    border-radius: 0;
                    @include responsive(md){
                        border-radius: 20px 20px 0 0;
                    }
                }
            }
            :first-child{
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }
            :last-child{
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
        .table-row{
            display: flex;
            padding: 16px;
            background-color: #f4e9d7;
            align-items: center;
            margin-bottom: 10px;
            @include responsive(md){
                flex-wrap: wrap;
            }
            &.table-head{
                margin-bottom: 0px;
                background-color: #996936;
                border-radius: 20px 20px 0 0;
                &:not(.mobile){
                    @include responsive(md){
                        display: none;
                    }    
                }
            }
            &.mobile{
                display: none;
                text-align: center;
                border-radius: 20px 20px 0 0;
                @include responsive(md){
                    display: block;
                }
            }
            .cell{
                flex: 1;
                text-align: center;
                padding: 10px;
                &:not(.head){
                    font-family: "Charlevoix Light", Sans-serif;
                    font-size: 18px;
                    @include responsive(md){
                        font-size: 16px;
                    }
                }
                &.head{
                    font-family: "bentago";
                    color: white;
                    font-size: 30px;
                    @include responsive(md){
                        font-size: 25px;
                        justify-content: center;
                    }
                }
                &.bed-bath{
                    flex: 1.5;
                }
                &.residence:not(.head){
                    font-size: 35px;
                    font-family: "bentago";
                    span{
                        font-size: 26px;
                        margin-bottom: 8px;
                        display: none;
                        width: 100%;
                        text-align: center;
                        @include responsive(md){
                            display: block;
                        }
                    }
                    @include responsive(md){
                        border-bottom: 1px solid #5E75843D;
                        color: #996936;
                        flex: 100%;
                        padding-bottom: 15px;
                        margin-bottom: 10px;
                    }
                }
                span{
                    display: none;
                    font-family: "bentago";
                    @include responsive(md){
                        display: block;
                        width: 50%;
                        text-align: left;
                        font-size: 25px;
                    }
                }
                &:not(.residence){
                    @include responsive(md){
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        flex: 100%;
                    }
                }
                &.type{
                    @include responsive(md){
                        display: none;
                    }
                }
                &.view{
                    cursor: pointer;
                    transition: 100ms;
                    &:hover{
                        color: #996936;
                    }
                }
                &.floor-plan a{
                    cursor: pointer;
                    color: #000;
                    text-decoration: none;
                    transition: 100ms;
                    &:hover{
                        color: #996936;
                    }
                }
            }
        }
    }
}