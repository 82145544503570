.two-column{
    padding:0px 40px;
    position: relative;

    @include responsive(md){
        padding: 0 20px;
    }
    &.blue{
        background-color: #5e7584;
        .inner{
            .content-wrap{
                .heading{
                    color: #fff;
                }
                .sub-heading{
                    color: #fff;

                    span{

                        color: #f4e9d7;
                    }
                }
                .description{
                    color: #fff
                }
            }
        }
    }

    &.white{
        background-color: white;

        .inner{
            .content-wrap{
                .heading{
                    color: #000;
                }
                .sub-heading{
                    color: #8E591B;

                    span{

                        color: #5e7584;
                    }
                }
                .description{
                    color: #000;
                }
            }
        }
    }

    &.cream{
        background-color: #f4e9d7;

        .inner{
            .content-wrap{
                .heading{
                    color: #000;
                }
                .sub-heading{
                    color: #8E591B;

                    span{

                        color: #2d4241;
                    }
                }
                .description{
                    color: #000;
                }
            }
        }
    }

    &.green{
        background-color: #2d4241;

        .inner{
            .content-wrap{
                .heading{
                    color: #fff;
                }
                .sub-heading{
                    color: #f4e9d7;

                    span{

                        color: #5e7584;
                    }
                }
                .description{
                    color: #fff;
                }
            }
        }
    }

    &.brown{
        background-color: #231c1e;

        .inner{
            .content-wrap{
                .heading{
                    color: #fff;
                }
                .sub-heading{
                    color: #5e7584;

                    span{

                        color: #8E591B;
                    }
                }
                .description{
                    color: #fff;
                }
            }
        }
    }

    .inner{
        max-Width:1440px;
        margin: 0 auto;
        padding: 100px 0px;
        gap: 100px;
        align-items: center;
    
        @include responsive(xl){
            gap: 50px;
        }
        @include responsive(md){
            gap: 20px;
            padding: 150px 0;
        }
        @include responsive(sm){
            flex-direction: column !important;
            padding: 50px 0;
            gap: 30px;
        }
    
        .image-wrap{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include responsive(sm){
                width: 80%;
            }            
    
            img{
                width: 100%;
                object-fit: contain;
            }
        }   
        .content-wrap{
            width: 40%;

            @include responsive(sm){
                width: 100%;
            }
    
            .heading{
                font-size: 30px;
                margin-bottom: 20px;
                font-family: 'Charlevoix Pro';
    
                @include responsive(xl){
                    font-size: 26px;
                }
                @include responsive(md){
                    font-size: 20px;
                    margin-bottom: 10px;
                }
            }
            .sub-heading{
                font-size: 87px;
                font-family: 'Bentago';
                line-height: 100%;
                margin-bottom: 40px;
                max-width: 500px;
    
                @include responsive(xl){
                    font-size: 65px;
                }
                @include responsive(md){
                    font-size: 55px;
                    margin-bottom: 20px;
                }
            }
            .description{
                P{
                    font-size: 18px;
                    font-family: 'Charlevoix Pro';
                    margin-bottom: 20px;
                    line-height: 200%;
    
                    &:last-child{
                        margin: 0;
                    }
    
                    @include responsive(xl){
                        font-size: 16px;
                    }
                    @include responsive(md){
                       margin-bottom: 10px;
                    }
                }
            }
            .logo{
                width: 10%;
                position: absolute;
                top: 15px;
                right: 5%;
               

                @include responsive(md){
                    inset: 0;
                    top: 0;
                    left: 10px;
                    width: 100px;
                }
                @include responsive(xs){
                    width: 70px;
                }
                
                img{
                    width: 100%;
                    opacity: 0.7;
                }
            }
        }
    }
}