.quick-navigation{
    background-color: #231C1E;
    padding: 0 40px;

    @include responsive(md){
        padding: 0 20px;
    }

    .inner{
        max-width: 1400px;
        margin: 0 auto;
        padding: 60px 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 2px solid white;

        @include responsive(sm){
            justify-content: center;
        }


        .prev-page{
            cursor: pointer;
            padding: 12px 22px;
            display: flex;
            gap: 20px;
            transition: 200ms;
            text-decoration: none;
            align-items: center;
            justify-content: flex-end;
            text-decoration-color: #FFC588;

            &:hover{
                text-decoration: underline;
                text-decoration-color: #FFC588;
                p{
                    
                    color: #FFC588;
                }
                .arrow{
                    path{
                        stroke: #FFC588 ;
                    }
                }
            }

            @include responsive(lg){
                padding: 12px 22px 12px 0px;
            }

            @include responsive(sm){
                order: 1;
                gap: 10px;
            }
            @include responsive(xs){
                gap: 5px;
                padding: 12px 8px 12px 0px;
            }

            .arrow{
                width: 35px;  
                path{
                    transition: 200ms;
                }

                @include responsive(xs){
                    width: 25px;
                }
            }

            p{
                color: #FAE9D7;
                font-size: 31px;
                font-family: 'Bentago';
                text-transform: capitalize;
                transition: 200ms;

                @include responsive(md){
                    font-size: 24px;
                }
                @include responsive(sm){
                    font-size: 18px;
                }
                @include responsive(xs){
                    font-size: 14px;
                }
            }
        }
        .heading-wrap{
            padding: 15px;
            width: 50%;
            text-align: center;

            @include responsive(sm){
                order: 0;
                width: 100%;
                text-align: center;
            }

            h2{
                color: #C29F7B;
                font-size: 62px;
                font-family: 'Bentago';
                transition: all 0.4s ease-in-out;

                @include responsive(lg){
                    font-size: 50px;
                }
                @include responsive(md){
                    font-size: 30px;
                }
                @include responsive(sm){
                    font-size: 28px;
                }
            }
        }

        .next-page{
            cursor: pointer;
            padding: 12px 22px;
            display: flex;
            align-items: center;
            text-decoration: none;
            gap: 20px;
            justify-content: flex-start;            
            text-decoration-color: #FFC588;

            &:hover{
                text-decoration: underline;
                text-decoration-color: #FFC588;
                p{
                    
                    color: #FFC588;
                }
                .arrow{
                    path{
                        stroke: #FFC588 ;
                    }
                }
            }

            @include responsive(lg){
                padding: 12px 0px 12px 22px;
            }
            @include responsive(sm){
                order: 2;
                gap: 10px;
            }
            @include responsive(xs){
                gap: 5px;
                padding: 12px 0 12px 8px;
            }

            p{
                color: #FAE9D7;
                font-size: 31px;
                font-family: 'Bentago';
                text-transform: capitalize;
                transition: 200ms;

                &:hover{
                    text-decoration: underline;
                    color: #FFC588;
                }

                @include responsive(md){
                    font-size: 24px;
                }
                @include responsive(sm){
                    font-size: 18px;
                }
                @include responsive(xs){
                    font-size: 14px;
                }
            }
            .arrow{
                width: 35px;
                path{
                    transition: 200ms;
                }
                @include responsive(xs){
                    width: 25px;
                }
            }
        }
    }
}