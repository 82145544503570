body, html{
    overflow-x:hidden;
}
body{
    margin-top: 58px;
}
html{
    scroll-behavior: smooth;
}
*, :after, :before{
    margin:0;
    padding:0;
    box-sizing:border-box
}

