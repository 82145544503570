.hero{
    background-image: linear-gradient(360deg, #5E7584 0%, #F4E9D701 25%);
    .background-image{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -1;
        @media(max-width:1370px){
            height: 100vh;
            object-fit: cover;
        }        
        @include responsive(md){
            position: absolute;
            height: 72vh;
        }
        @include responsive(sm){
            height: 68vh;
        }
        @include responsive(xs){
            height: 75vh;
        }
        @media(max-height:945px){
            height: 100%;
        }
    }
    .inner{
        max-width: 1440px;
        margin: 0 auto;
        padding: 220px 100px 305px 100px;
        display: flex;
        justify-content: center;
        @include responsive(lg){
            padding: 378px 100px 340px 100px;
        }
        @include responsive(md){
            padding: 243px 80px 145px 80px;
        }
        @include responsive(sm){
            padding: 233px 20px 150px 20px;
        }
        @include responsive(xs){
            padding: 180px 20px 78px 20px;
        }
        .content{
            text-align: center;
            color: #fff;
            max-width: 920px;
            padding-bottom: 35px;
            @include responsive(xs){
                padding-bottom: 20px;
                max-width: 378px;
            }
            .heading{
                font-family: 'Bentago';
                font-size: 87px;
                line-height: 1.3em;
                margin-bottom: 20px;
                font-weight: 500;
                @include responsive(md){
                    font-size: 70px;
                }
                @include responsive(sm){
                    font-size: 48px;
                }
            }
            .description{
                color: #fff;
                font-size: 18px;
                font-family: 'Charlevoix Pro';
                line-height: 1.8em;
                font-weight: lighter;
                @include responsive(md){
                    font-size: 16px;
                }
            }
            a{
                display: inline-block;
                font-size: 16px;
                color: #fff;
                cursor: pointer;
                font-family: "Charlevoix Pro";
                border: 1px solid #fff;
                text-decoration: none;
                padding: 20px 30px;
                margin-top: 40px;

                &:hover{
                    transition: 0.3s ease;
                    color: #F4E9D7;
                    border-color: #F4E9D7;
                }
            }
        }
    }
}