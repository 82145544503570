.specials-banner{
    padding: 15px;
    background-color: #5e7584;
    position: absolute;
    top: 0;
    width: 100%;
    .inner{
        display: flex;
        align-items: center;
        justify-content: center;
        .text{
            color: white;
            font-family: 'Charlevoix Pro';
            font-size: 20px;
            padding-bottom: 2px;
            text-align: center;
            border-bottom: 2px solid #fff;
            @include responsive(md){
                font-size: 16px;
            }
            @include responsive(sm){
                font-size: 14px;
            }
            @include responsive(xs){
                font-size: 12px;
            }
        }
    }

}