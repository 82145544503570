.show3d-modal{
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 1111111111;
    .inner{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        .wrapper{
            position: relative;
            display: flex;
            img{
                max-width: 1000px;
                width: 100%;
                padding: 0 10px;
            }
            @include responsive(md){
                position: static;
            }
        }
        .close-show3d-modal{
            width: 30px;
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: #000000;
            border: 1px solid white;
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
            &:hover{
                scale: 1.03;
            }
            @include responsive(md){
                right: 10px;
            }
        }
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #222222;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  