.up-arrow-circle{
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #C29F7B;
    border-radius: 50%;
    z-index: 120;
    transition: 300ms;
    opacity: 0;
    pointer-events: none;
    &.scrolled{
        opacity: 1;
        pointer-events: all;
        @include responsive(sm){
            display: none;
        }
    }
    &:hover{
        background-color: #C29F7B;
    }
    @include responsive(sm){
        display: none;
    }
}